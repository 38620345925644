/* TODO: remove unused fonts */

/* never used */
@font-face {
  font-family: "Inter";
  font-weight: 900;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-Black.ttf) format("truetype");
}

/* never used */
@font-face {
  font-family: "Inter";
  font-weight: 800;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-ExtraBold.ttf) format("truetype");
}

/* never used */
@font-face {
  font-family: "Inter";
  font-weight: 700;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 600;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 500;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  font-weight: 400;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-Regular.ttf) format("truetype");
}

/* never used */
@font-face {
  font-family: "Inter";
  font-weight: 300;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-Light.ttf) format("truetype");
}

/* never used */
@font-face {
  font-family: "Inter";
  font-weight: 200;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-ExtraLight.ttf) format("truetype");
}

/* never used */
@font-face {
  font-family: "Inter";
  font-weight: 100;
  src: local("Inter"),
    url(../public/fonts/Inter/Inter-Thin.ttf) format("truetype");
}
